import { useHistory } from "react-router-dom"

// Chakra imports
import { Box, Flex, Grid, IconButton, Text, VStack } from '@chakra-ui/react';

// Assets
import SpotlightUserCard from './SpotlightUserCard';
import { Timestamp } from "firebase/firestore";
import SpotlightNotes from './SpotlightNotes';
import SpotlightConsole from './SpotlightConsole';
import SpotlightFriends from './SpotlightFriends';
import SpotlightConversations from './SpotlightConversations';
import SpotlightPrivacy from './SpotlightPrivacy';
import ColorModeParagraph from
    "lib/coplay/components/views/dashboard/dashboard_list/spotlight/ColorModeParagraph"
import PrivilegesLink
    from "lib/coplay/components/views/dashboard/dashboard_list/spotlight/PrivilegesLink"
import SpotlightPrivileges from
    "lib/coplay/components/views/dashboard/dashboard_list/spotlight/SpotlightPrivileges"
import {
    subscribeToConsoleDetails,
    subscribeToConversations,
    subscribeToFriends,
    subscribeToSettings,
    subscribePrivileges,
    subscribeToXboxUser,
    updateNotes
} from 'lib/coplay/backend/FirebaseFunctions';
import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import Card from "lib/horizon/components/card/Card"
import { changeSettings, clearConversations } from 'lib/coplay/backend/ApiRequests';
import { XboxUser, Friends, XBLCollatedConversations, XboxConsole, UserPrivacySettings, UserStatusMessage, OauthActions } from "lib/coplay/types/BackendTypes";


const useSpotlightData = (user?: XboxUser) => {

    const [userState, setUserState] = useState<XboxUser | null>(null);
    const [friends, setFriends] = useState<Friends | null>(null);
    const [conversations, setConversations] = useState<XBLCollatedConversations | null>(null);
    const [xboxConsole, setXboxConsole] = useState<XboxConsole | null>(null);
    const [settings, setSettings] = useState<UserPrivacySettings | null>(null);
    const [getPrivileges, setPrivileges] = useState<{ [key: string]: string }[]>([])

    useEffect(() => {
        if (user === null || user === undefined) {
            setUserState(null);
            setFriends(null);
            setConversations(null);
            setSettings(null);
            setPrivileges(undefined)
            setXboxConsole(null);
        } else {

            const unsubUser = subscribeToXboxUser(user.emailAddress, setUserState);
            const unsubFriends = subscribeToFriends(user.emailAddress, setFriends);
            const unsubConversations = subscribeToConversations(user.emailAddress, setConversations)
            const unsubSettings = subscribeToSettings(user.emailAddress, setSettings);
            const unsubscribePrivileges = subscribePrivileges(user.emailAddress, setPrivileges)
            const unsubConsole = subscribeToConsoleDetails(user.emailAddress, setXboxConsole);

            return () => {
                unsubUser();
                unsubFriends();
                unsubConversations();
                unsubSettings();
                unsubscribePrivileges()
                unsubConsole();
            }
        }
    }, [user]);

    return {
        userState,
        friends,
        conversations,
        xboxConsole,
        settings,
        getPrivileges
    }

}


export default function SpotlightView(props: { user?: XboxUser }) {
    const history = useHistory<undefined>()

    const { userState, friends, conversations, xboxConsole, settings, getPrivileges }
        = useSpotlightData(props.user);

    useEffect(() => {
        if (userState) {
            window.scrollTo(0, 0);
        }
    }, [userState]);

    const notesUpdate = (notes: string) => { userState && updateNotes(userState, notes) }

    const clearConvos = async () => { userState && await clearConversations([userState.emailAddress]) }

    const updateSettings = async (settings: UserPrivacySettings) => { userState && await changeSettings([userState.emailAddress], settings) }


    if (userState == null || userState == undefined)
        return <SpotlightZeroState />

    return (
        <Box>
            <IconButton
                aria-label="Go back"
                icon={<MdArrowBack />}
                onClick={() => history.push("/app/dashboard")}
                mb={4} />
            <Grid
                templateColumns={{
                    base: '1fr',
                    lg: '1.34fr 1fr 1.62fr'
                }}
                templateRows={{
                    base: 'repeat(3, 1fr)',
                    lg: '1fr'
                }}
                gap={{ base: '20px', xl: '20px' }}>
                <SpotlightUserCard
                    user={userState}
                    gridArea='1 / 1 / 2 / 2'
                />

                <SpotlightNotes
                    gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
                    notes={userState?.notes}
                    updateNotes={notesUpdate} />
                <SpotlightConsole
                    gridArea={{
                        base: '3 / 1 / 4 / 2',
                        lg: '1 / 3 / 2 / 4'
                    }}
                    minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                    pe='20px'
                    pb={{ base: '100px', lg: '20px' }}
                    emailAddress={userState?.emailAddress}
                    xboxConsole={xboxConsole}
                />
            </Grid>
            <Grid
                mb='20px'
                templateColumns={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                    '2xl': '1.34fr 1.62fr 1fr'
                }}
                templateRows={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                    '2xl': '1fr'
                }}
                gap={{ base: '20px', xl: '20px' }}>


                <Card style=
                    {{ marginBottom: "20px", maxHeight: "500px", overflowY: "scroll" }}>
                    <ColorModeParagraph
                        lightMode={"secondaryGray.900"}
                        darkMode={"white"}
                        style={
                            { marginBottom: "4px", fontSize: "24px", fontWeight: "bold" }
                        }
                    >
                        Privileges
                    </ColorModeParagraph>
                    <Text
                        style={{ marginRight: "26px", marginBottom: "15px" }}
                        color={"gray.400"}
                    >
                        These permissions can be adjusted from <PrivilegesLink />.
                    </Text>
                    <VStack spacing={4} style={{ alignItems: "stretch" }}>
                        <SpotlightPrivileges getPrivileges={getPrivileges} />
                    </VStack>
                </Card>
                <SpotlightFriends
                    friends={friends}
                />
                <SpotlightConversations
                    conversations={conversations}
                    onClear={clearConvos}
                    gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
                    minH='365px'
                    pe='20px' />
            </Grid>
            <SpotlightPrivacy
                onSettings={updateSettings}
                settings={settings}
            />
        </Box>
    );
}

function SpotlightZeroState() {

    return (
        <Flex h='90vh' w='100%' id="profileZeroState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"}>
            <Box maxW="50%">
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    Select a user to see their details
                </Text>
            </Box>
        </Flex>
    )

}

function spotlightLoadingState() {
    return (
        <Flex h='90vh' w='100%' id="profileZeroState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"}>
            <Box maxW="50%">
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    Loading...
                </Text>
            </Box>
        </Flex>
    )

}

const dummyUser = {
    emailAddress: 'dummy.email@example.com',
    gamerTag: 'DummyGamer123',
    xuid: '1234567890',
    profilePicUrl: 'https://example.com/dummy-profile-pic.jpg',
    numFriends: 10,
    numConversations: 5,
    updatedAt: Timestamp.now(),
    requestID: -1,
    userStatus: {
        statusMessage: UserStatusMessage.Ready,
        lastAction: {
            oauthAction: OauthActions.Refresh,
            requestID: -1
        },
    },
    presenceState: 'In-Game',
    presenceText: 'Playing Halo Infinite',
    consoleId: 'dummyConsoleId123',
    notes: 'This is a dummy user for demonstration.',
};

const dummyFriends: Friends = {
    totalCount: 25,
    people: [
        { xuid: "1000000001", gamertag: "SkyWalker" },
        { xuid: "1000000002", gamertag: "PixelHunter" },
        { xuid: "1000000003", gamertag: "StealthNinja" },
        { xuid: "1000000004", gamertag: "IronGiant" },
        { xuid: "1000000005", gamertag: "MoonWatcher" },
        { xuid: "1000000006", gamertag: "SeaSorcerer" },
        { xuid: "1000000007", gamertag: "DragonTamer" },
        { xuid: "1000000008", gamertag: "CodeWizard" },
        { xuid: "1000000009", gamertag: "ThunderStrike" },
        { xuid: "1000000010", gamertag: "FireBender" },
        { xuid: "1000000011", gamertag: "SpaceCowboy" },
        { xuid: "1000000012", gamertag: "ShadowMaster" },
        { xuid: "1000000013", gamertag: "DigitalSamurai" },
        { xuid: "1000000014", gamertag: "FrostMage" },
        { xuid: "1000000015", gamertag: "LaserShark" },
        { xuid: "1000000016", gamertag: "CyberPunk" },
        { xuid: "1000000017", gamertag: "StarCaptain" },
        { xuid: "1000000018", gamertag: "WitchDoctor" },
        { xuid: "1000000019", gamertag: "AstroGlider" },
        { xuid: "1000000020", gamertag: "EagleEye" },
        { xuid: "1000000021", gamertag: "WindWalker" },
        { xuid: "1000000022", gamertag: "SilverSword" },
        { xuid: "1000000023", gamertag: "GoldenFist" },
        { xuid: "1000000024", gamertag: "TechGuru" },
        { xuid: "1000000025", gamertag: "BattleMage" }
    ]
};


const dummyConvos: XBLCollatedConversations = {
    primary: {
        totalCount: 3,
        unreadCount: 1,
        conversations: [
            {
                conversationId: 'conv1',
                type: 'chat',
                lastMessage: {
                    messageId: 'msg1',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Hello, how are you?'
                                }
                            ]
                        }
                    }
                }
            },
            {
                conversationId: 'conv2',
                type: 'chat',
                lastMessage: {
                    messageId: 'msg2',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Any updates on the project?'
                                }
                            ]
                        }
                    }
                }
            },
            {
                conversationId: 'conv3',
                type: 'chat',
                lastMessage: {
                    messageId: 'msg3',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Meeting at 3 PM.'
                                }
                            ]
                        }
                    }
                }
            }
        ],
        raw: []
    },
    secondary: {
        totalCount: 2,
        unreadCount: 0,
        conversations: [
            {
                conversationId: 'conv4',
                type: 'group',
                lastMessage: {
                    messageId: 'msg4',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Let\'s finalize the plans.'
                                }
                            ]
                        }
                    }
                }
            },
            {
                conversationId: 'conv5',
                type: 'group',
                lastMessage: {
                    messageId: 'msg5',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Happy birthday!'
                                }
                            ]
                        }
                    }
                }
            }
        ],
        raw: []
    },
    grouped: {
        totalCount: 2,
        unreadCount: 0,
        conversations: [
            {
                conversationId: 'conv4',
                type: 'group',
                lastMessage: {
                    messageId: 'msg4',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Let\'s finalize the plans.'
                                }
                            ]
                        }
                    }
                }
            },
            {
                conversationId: 'conv5',
                type: 'group',
                lastMessage: {
                    messageId: 'msg5',
                    contentPayload: {
                        content: {
                            parts: [
                                {
                                    contentType: 'text',
                                    text: 'Happy birthday!'
                                }
                            ]
                        }
                    }
                }
            }
        ],
        raw: []
    },
    totalConversations: 5,
    totalUnread: 1
};

const dummySettings: UserPrivacySettings = {
    settings: [
        { setting: "ShareFriendList", value: "Everyone" },
        { setting: "ShareGameHistory", value: "PeopleOnMyList" },
        { setting: "CommunicateUsingTextAndVoice", value: "FriendCategoryShareIdentity" },
        { setting: "SharePresence", value: "Blocked" },
        { setting: "ShareProfile", value: "Everyone" },
        { setting: "ShareVideoAndMusicStatus", value: "PeopleOnMyList" },
        { setting: "CommunicateUsingVideo", value: "PeopleOnMyList" },
        { setting: "ShareIdentity", value: "Everyone" },
        { setting: "ShareRecordedGameSessions", value: "PeopleOnMyList" },
        { setting: "ShareIdentityTransitively", value: "Blocked" },
        { setting: "AllowUserCreatedContentViewing", value: "FriendCategoryShareIdentity" },
        { setting: "AllowProfileViewing", value: "Blocked" },
        { setting: "ShareClubMembership", value: "FriendCategoryShareIdentity" },
        { setting: "ShareActivityFeed", value: "Everyone" },
        { setting: "CommunicateDuringCrossNetworkPlay", value: "PeopleOnMyList" },
        // { setting: "CollectVoiceData", value: "FriendCategoryShareIdentity" },
        // { setting: "ShareXboxMusicActivity", value: "Blocked" },
        // { setting: "CollectXboxVideoData", value: "FriendCategoryShareIdentity" },
        // { setting: "ShareVideoHistory", value: "Everyone" },
        // { setting: "ShareMusicHistory", value: "PeopleOnMyList" },
        // { setting: "ShareContentToExternalNetworks", value: "Everyone" },
        // { setting: "CollectVoiceSearchData", value: "PeopleOnMyList" },
        // { setting: "CollectVoiceGameChatData", value: "Blocked" },
    ]
};
