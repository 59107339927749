import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { Box, Flex } from "@chakra-ui/react";
import Card from "lib/horizon/components/card/Card";
import { ProfileLoadingState } from "lib/coplay/components/views/profiles/ProfileZerostate";
import CreateProfile from "./CreateProfile";
import { ProfilesList } from "lib/coplay/components/views/profiles/ProfileList";
import { deleteProfile, subscribeToProfiles } from "lib/coplay/backend/FirebaseFunctions";
import { ProfileDetails } from "lib/coplay/components/views/profiles/ProfileDetails";
import { XMProfile } from "lib/coplay/types/BackendTypes";

enum ProfileViewState {
    LOADING = "Loading",
    LIST = "List",
    CREATE = "Create",
    DETAILS = "Details"
}

export function ProfilesView() {
    const history = useHistory<ProfileViewState>()

    const [profiles, setProfiles] = useState<XMProfile[]>();
    const [selectedProfile, setSelectedProfile] = useState<XMProfile | null>(null);

    useEffect(() => {
        const unsubscribe = subscribeToProfiles((profiles) => {
            setProfiles(profiles);
            history.replace("/app/profiles", ProfileViewState.LIST)
        })
        return unsubscribe
    }, [])

    const onCreated = () => {
        history.push("/app/profiles", ProfileViewState.LIST)
    };

    const onDetails = (profile: XMProfile) => {
        console.log("Selected profile: ", profile)
        setSelectedProfile(profile);
        history.push("/app/profiles", ProfileViewState.DETAILS)
    };

    const onDelete = (profile: XMProfile) => {
        console.log("Deleting profile: ", profile)
        deleteProfile(profile).then(() => {
            console.log("Profile deleted")
        }).catch((err) => {
            console.log("Error deleting profile: ", err)
        })
    };


    const viewStateMap = {
        [ProfileViewState.LOADING]: <ProfileLoadingState />,
        [ProfileViewState.LIST]: <ProfilesList
            profiles={profiles}
            onCreate={() => history.push("/app/profiles", ProfileViewState.CREATE)}
            onDetails={onDetails}
            onDelete={onDelete}
        />,
        [ProfileViewState.CREATE]: <CreateProfile
            onCreated={onCreated}
            onBack={() => history.push("/app/profiles", ProfileViewState.LIST)}
        />,
        [ProfileViewState.DETAILS]: <ProfileDetails
            profile={selectedProfile}
            onDelete={onDelete}
            onBack={() => history.push("/app/profiles", ProfileViewState.LIST)}
        />
    }
    let viewState: ProfileViewState
    if (profiles) {
        if (history.location.state) {
            viewState = history.location.state
        } else {
            viewState = ProfileViewState.LIST
        }
    } else {
        viewState = ProfileViewState.LOADING
    }

    return (
        <Box pt={{ base: '80px', xl: '80px' }} pb="10px" h="93vh" >

            <Card h='100%' overflowY='scroll' >
                <Flex
                    align={{ sm: 'flex-start', lg: 'center' }}
                    justify='space-between'
                    w='100%'
                >
                </Flex>
                {viewStateMap[viewState]}
            </Card>

        </Box>
    )
}
