import { Box, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { XboxIcon } from "../XboxIcon";

export const ConsoleDetailsButton = (props: { consoleId: string, consoleName?: string, consoleOs?: string, emailAddress: string, onClick: () => void }) => {
    const brandColor = useColorModeValue('brand.500', 'white');
    const iconColor = useColorModeValue('#000000', 'white');

    console.log("consoleName ", props.consoleName)

    return (
        <Box textAlign={'left'} minH='70px' >
            <XboxIcon color={iconColor} />
            <Text marginTop={"-15px"} color={brandColor} fontWeight='bold' fontSize='xs' _hover={{ fontSize: 'sm' }} onClick={props.onClick}>
                {props.consoleName && (
                    <>
                        {props.consoleName}
                        <br />
                    </>

                )}
                {props.consoleOs && (
                    <>
                        OS: {props.consoleOs}
                        <br />
                    </>
                )}
                {props.consoleId}  {props.consoleName ? (<br />) : null}
            </Text>
        </Box >
    );
}   