// Chakra imports
import { Text, Box, Flex, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
// Layout components
import AppNavBar from 'lib/coplay/components/app_layout/AppNavBar';
import { ContentArea } from 'lib/coplay/components/app_layout/ContentArea';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { AppSidebar, SidebarLink } from 'lib/coplay/components/app_layout/AppSidebar';
import Brand from 'lib/horizon/components/sidebar/components/Brand';
import { RemoveFriendsActionLink } from 'lib/coplay/components/actions/friending/RemoveFriendActionLink';
import { SyncFriendsActionLink } from 'lib/coplay/components/actions/friending/SyncFriendsActionLink';
import { ClearConversationsActionLink } from 'lib/coplay/components/actions/ClearConversationsActionLink';
import { RefreshUsersActionLink } from 'lib/coplay/components/actions/user/RefreshUsersActionLink';
import { MdBarChart, MdHome, MdPerson, MdGroups, MdCalendarMonth, MdCode, MdSettings } from 'react-icons/md';
import AccountView from 'lib/coplay/components/views/account/AccountView';
import UsageView from 'lib/coplay/components/views/usage/UsageView';
import Dashboard from 'lib/coplay/components/views/dashboard/Dashboard';
import { DeleteUsersActionLink } from 'lib/coplay/components/actions/user/DeleteUsersActionLink';
import { ProfilesView } from './views/profiles/ProfilesView';
import { SetProfileActionLink } from './actions/user/SetProfileActionLink';
import { RemoveProfileActionLink } from './actions/user/RemoveProfileActionLink';
import { SyncProfileActionLink } from './actions/user/SyncProfileActionLink';
import { ScheduleActionLink } from './actions/scheduling/ScheduleActionLink';
import SchedulesView from './views/schedules/SchedulesView';
import { BatchInstallActionLink } from './actions/BatchInstallActionLink';
import APIView from './views/api/APIView';


export default function App(props: { [x: string]: any }) {
    const location = useLocation();
    const isDashboard = location.pathname === '/app/dashboard';

    return (
        <Box>

            <AppNavBar />

            <AppSidebar>
                <Brand key="Brand" />
                <Accordion allowMultiple index={isDashboard ? [0] : []}>

                    <AccordionItem border='none' >
                        <Box position="relative">
                            <SidebarLink label="Dashboard" icon={MdHome} routeName={'/app/dashboard'}>
                                <></>
                                <Flex border='' position="absolute" right={0} top={0} bottom={0} w='25%' justifyContent="center" alignItems="center">
                                    <AccordionButton m={5}>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </Flex>
                            </SidebarLink>
                        </Box>

                        <AccordionPanel _hover={{ cursor: 'pointer' }} pb={4} pl={'60px'}>
                            <Text fontSize='sm' fontWeight={'bold'}> User Actions</Text>

                            <SyncFriendsActionLink key="SyncFriendsActionLink" />
                            <RemoveFriendsActionLink key="RemoveFriendsActionLink" />
                            <ClearConversationsActionLink key="ClearConversationsActionLink" />
                            <RefreshUsersActionLink key="RefreshUsersActionLink" />
                            <DeleteUsersActionLink key="DeleteUsersActionLink" />

                            <Text fontSize='sm' fontWeight={'bold'}> Console Actions</Text>
                            <BatchInstallActionLink key="BatchInstallActionLink" />


                            <Text fontSize='sm' fontWeight={'bold'}> Profile Actions </Text>
                            <SetProfileActionLink key="SetProfileActionLink" />
                            <RemoveProfileActionLink key="RemoveProfileActionLink" />
                            <SyncProfileActionLink key="SyncProfileActionLink" />

                            <Text fontSize='sm' fontWeight={'bold'}> Scheduling </Text>
                            <ScheduleActionLink key={"Schedule"} />

                        </AccordionPanel>

                    </AccordionItem>

                    <SidebarLink label="Usage" icon={MdBarChart} routeName={'/app/usage'} />
                    <SidebarLink label="Create/View Profiles" icon={MdGroups} routeName={'/app/profiles'} />
                    <SidebarLink label="View Schedules" icon={MdCalendarMonth} routeName={'/app/schedules'} />
                </Accordion>

                <Accordion allowMultiple >
                    <AccordionItem border='none'>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                <Flex w='100%' ml={'12px'} align="center">
                                    <MdSettings />
                                    <Text ml={'20px'} fontSize='md'> Settings </Text>
                                </Flex>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel m={0} p={0} pb={4} w='100%'>
                            <Box w='100%' px={'15px'} mx={'15px'}>
                                <SidebarLink label="Account" icon={MdPerson} routeName={'/app/account'} />
                                <SidebarLink label="Developer" icon={MdCode} routeName={'/app/api'} />
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>
            </AppSidebar >

            <ContentArea >
                <Switch>
                    <Route exact path='/app/dashboard' component={Dashboard} />
                    <Route exact path='/app/usage' component={UsageView} />
                    <Route exact path='/app/account' component={AccountView} />
                    <Route exact path='/app/profiles' component={ProfilesView} />
                    <Route exact path='/app/schedules' component={SchedulesView} />
                    <Route exact path='/app/api' component={APIView} />
                    <Route path={"*"}>
                        <Redirect to={"/app/dashboard"} />
                    </Route>
                </Switch>
            </ContentArea>

        </Box >
    );
}