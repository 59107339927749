import { CheckIcon, CloseIcon, EditIcon, WarningIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, ButtonGroup, Editable, EditableInput, EditablePreview, Flex, IconButton, Input, Spinner, Text, useEditableControls } from "@chakra-ui/react";
import { renameConsole } from "lib/coplay/backend/ApiRequests";
import { XboxConsole } from "lib/coplay/types/BackendTypes";
import { useState } from "react";


export const XboxConsoleName = (props: { emailAddress: string, console: XboxConsole }) => {

    const [consoleName, setConsoleName] = useState(props.console.name);
    const [state, setState] = useState<"Ready" | "Loading" | "Error">("Ready")

    function handleChange(newValue: string) {
        setConsoleName(newValue);
    }

    const onSubmit = () => {
        if (consoleName == props.console.name)
            return

        setState("Loading")
        console.log("Renaming console to: ", consoleName)
        renameConsole(props.emailAddress, props.console.id, consoleName)
            .then((res) => {
                console.log("Success: ", res)
                setState("Ready")
            })
            .catch((e) => {
                console.log("Error changing console name: ", e)
                setState("Error")
            })
            .finally(() => {
                console.log("Renaming Finsihed")
            })
    }

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()

        if (state === "Loading") {
            return (
                <Flex pl='5px' alignItems={'center'}>
                    <Spinner pl="10px" />
                </Flex>
            )
        } else if (state === "Error") {
            return (
                <Flex pl='5px' alignItems={'center'}>
                    <WarningIcon color='tomato' />
                    <Text pl='5px' size='small' color='tomato'> Something went wrong! </Text>
                </Flex>

            )
        }
        else if (isEditing)
            return (
                <Flex>
                    <IconButton size='xs' aria-label="Submit" icon={<CheckIcon />} {...getSubmitButtonProps()} />
                    <IconButton size='xs' aria-label="Cancel" icon={<CloseIcon />} {...getCancelButtonProps()} />
                </Flex>
            )
        else
            return (
                <IconButton aria-label="Edit" size='sm' icon={< EditIcon />} {...getEditButtonProps()} />
            )

    }

    return (
        <Flex alignContent={"center"} alignItems={"center"}>

            <Text pb='3px' pr='4px'><strong>Name: </strong></Text>
            <Editable
                textAlign='center'
                defaultValue={props.console.name}
                fontSize='md'
                isPreviewFocusable={false}
                py='0px'
                value={consoleName}
                onChange={handleChange}
                onSubmit={onSubmit}
            >
                <Flex>
                    <EditablePreview />
                    {/* Here is the custom input */}
                    <Input as={EditableInput} />
                    <EditableControls />
                </Flex>
            </Editable>
        </Flex>
    )
}
