import { Text, Box, HStack, Flex, Icon, ModalHeader, Button, ModalBody, ModalCloseButton, ModalFooter } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React from "react";
import { MdGroupAdd, MdGroupOff, MdSyncAlt } from "react-icons/md";
import { handleFriending } from "../../backend/ApiRequests";
import { useSelectedUsers, useXboxUsers } from "../DataProvider";
import { addXboxUsersObserver } from "../../backend/FirebaseFunctions";
import { ViewState } from "./BaseAction";
import { BasicResponse, OauthActions } from "lib/coplay/types/BackendTypes";

export const ACTION_LINK_TIMEOUT: number = 20000;

export function FriendingScreen(props: { handleClose: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>, setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>, action: OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends }) {

    const selectedUsers = useSelectedUsers();
    const allXboxUsers = useXboxUsers();


    let header = '';
    let body = '';
    let friendXuids: string[] = [];

    if (props.action === OauthActions.AddFriends) {
        header = 'Add All Friends';
        body = `Are you sure you want to add every friend in CoPlay XM to ${selectedUsers.size()} user(s)?`;
        friendXuids = allXboxUsers.map(user => user.xuid.toString());
    } else if (props.action === OauthActions.RemoveFriends) {
        header = 'Remove All Friends';
        body = `Are you sure you want to remove every friend in CoPlay XM from ${selectedUsers.size()} user(s)?`;
        friendXuids = ['all']
    } else if (props.action === OauthActions.SyncFriends) {
        header = 'Sync Friends';
        body = `Are you sure you want to sync every friend in CoPlay XM with ${selectedUsers.size()} user(s)?`;
        friendXuids = allXboxUsers.map(user => user.xuid.toString());
    }

    const handler: () => void = () => {

        props.setViewState(ViewState.Loading);
        const timeout = setTimeout(() => {
            props.setViewState(ViewState.Warning);
        }, ACTION_LINK_TIMEOUT);

        addXboxUsersObserver(() => {
            props.handleClose();
            clearTimeout(timeout);
        })

        const selected: string[] = [];
        selectedUsers.forEach(user => selected.push(user.emailAddress))
        handleFriending(selected, friendXuids, props.action).then((response) => {
            props.setActionResponse(response);
            // props.setViewState(ViewState.Success);
            clearTimeout(timeout);
        }).catch((error) => {
            console.log(`${props.action} Error`, error)
            props.setViewState(ViewState.Error);
            clearTimeout(timeout);
        });
    }

    return (
        <>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Text py='25px'>{body}</Text>
                    <Button width='200px' variant="brand" onClick={handler}>Confirm</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}


export function FriendingLinkAction(props: { onClickHandler: () => void, action: OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends }) {

    const selectedUsers = useSelectedUsers();

    let label = '';
    let icon = <></>;

    let friendXuids: string[] = [];
    if (props.action === OauthActions.AddFriends) {
        selectedUsers.forEach(user => friendXuids.push(user.xuid));
        label = 'Add All Friends';
        icon = <Icon as={MdGroupAdd} width='20px' height='20px' />
    } else if (props.action === OauthActions.RemoveFriends) {
        friendXuids = ['all'];
        label = 'Remove All Friends';
        icon = <Icon as={MdGroupOff} width='20px' height='20px' />
    } else if (props.action === OauthActions.SyncFriends) {
        selectedUsers.forEach(user => friendXuids.push(user.xuid));
        label = 'Sync Friends';
        icon = <Icon as={MdSyncAlt} width='20px' height='20px' />
    }

    return (
        <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
    )
}


export function ActionLinkElem(props: { onClickHandler: () => void, label: string, icon: JSX.Element, isActive: boolean }) {

    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue('secondaryGray.500', 'secondaryGray.600');
    let onHoverColor = useColorModeValue('brand.500', 'white');
    const color = props.isActive ? activeColor : inactiveColor;

    const test = () => {
        props.onClickHandler();
    }

    return (
        <Box onClick={props.isActive ? test : null} key={props.label}>
            <HStack
                py='5px'
                ps='30px'>
                <Flex w='100%' alignItems='center' justifyContent='center' _hover={{ color: onHoverColor }}>
                    <Box
                        color={color}
                        me='18px'>
                        {props.icon}
                    </Box>
                    <Text
                        me='auto'
                        color={color}
                        _hover={props.isActive ? { color: onHoverColor } : null}
                    >
                        {props.label}
                    </Text>
                </Flex>
                <Box
                    h='36px'
                    w='4px'
                    borderRadius='5px'
                />
            </HStack>
        </Box>
    )
}

