import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { useIsLoggedIn } from "lib/coplay/components/DataProvider"
import XboxCodeView from 'lib/coplay/components/views/XboxCodeView';
import Signout from 'lib/coplay/components/actions/Signout';
import App from 'lib/coplay/components/App';
import SignIn from 'lib/coplay/components/views/auth/SignIn';

function CoPlayRouter() {
    const isLoggedIn = useIsLoggedIn()
    
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/sign-out' component={Signout} />
                <Route path='/sign-in' component={SignIn} />
                {!isLoggedIn && <Redirect to = {"/sign-out"} />}
                <Route exact path='/code' component={XboxCodeView} />
                <Route path='/app' component={App} />
                <Route path='*'>
                    <Redirect to="/app/dashboard" />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}



export default CoPlayRouter;