import { Flex, Spinner, Text } from "@chakra-ui/react";


export function Loading() {
    return (
        <>
            <Flex direction='column' alignItems='center' justifyContent='center' py='50px'>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex>
            <Text py='25px' align='center'>
                Wait for it...
            </Text>
        </>
    )
}