import { useState } from 'react';
import { Box, Text, Divider, Flex, Collapse } from '@chakra-ui/react';
import { BasicReport, BasicResponse, ResponseStatus } from 'lib/coplay/types/BackendTypes';
import Card from 'lib/horizon/components/card/Card';

interface RequestLogRendererProps {
    requestLog: (BasicReport | BasicResponse)[];
}

const getStatusColor = (status: ResponseStatus) => {
    switch (status) {
        case ResponseStatus.Success:
            return 'green.500';
        case ResponseStatus.Fail:
            return 'red.500';
        case ResponseStatus.Mixed:
            return 'yellow.500';
        case ResponseStatus.Warning:
            return 'orange.500';
        case ResponseStatus.Pending:
            return 'blue.500';
        default:
            return 'gray.500';
    }
};

export function RequestLogRenderer(props: { requestLog: (BasicReport | BasicResponse)[] }) {
    return (
        <Card overflowX="auto">
            <Text fontSize="lg" fontWeight="bold">Recent Requests</Text>
            {props.requestLog.map((entry, index) => (
                <Box key={index}>
                    <ResultRenderer result={entry} />
                </Box>
            ))}
        </Card>
    );
}

function ResultRenderer(props: { result: BasicReport | BasicResponse }) {
    const [isOpen, setIsOpen] = useState(false);
    const status = 'status' in props.result ? props.result.status : props.result.statusCode;
    const action = props.result.oauthAction;
    const date = new Date(props.result.requestId).toLocaleString();


    return (
        <>
            <Divider />
            <Box onClick={() => setIsOpen(!isOpen)} p={2} cursor="pointer">
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>Action:</Text>
                        <Text>{formatOauthAction(action)}</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Flex textAlign="left" mx={4} as="span" >
                            <Text fontWeight="bold" mr={1}>Date:</Text>
                            <Text>{date}</Text>
                        </Flex>

                        <Text fontWeight="bold" mr={1}>Status:</Text>
                        <Box as="span" color={getStatusColor(status)}>
                            {ResponseStatus[status]}
                        </Box>
                    </Flex>
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    <Box mt={2}>
                        {('status' in props.result) ? <ResponseRenderer response={props.result as BasicResponse} /> :
                            ('statusCode' in props.result) ? <ReportRenderer report={props.result as BasicReport} /> : 'N/A'}
                    </Box>
                </Collapse>
            </Box>
        </>
    );
}

function ResponseRenderer(props: { response: BasicResponse }) {

    return (
        <>
            <Box mt={2}>
                <Flex flexWrap="wrap" gap={4}>
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>User:</Text>
                        <Text>{props.response.emailAddress}</Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};

function formatOauthAction(oauthAction: string): string {
    return oauthAction
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
}

function ReportRenderer(props: { report: BasicReport }) {

    return (
        <>
            <Box mt={2}>
                <Flex flexWrap="wrap" gap={4}>
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>Num Users:</Text>
                        <Text>{props.report.numUsers}</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>Num Success:</Text>
                        <Text>{props.report.numSuccess}</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>Num Fails:</Text>
                        <Text>{props.report.numFails}</Text>
                    </Flex>
                </Flex>
            </Box>

        </>
    );
}