import { Box, Button, Flex, Spacer, Text, useColorModeValue } from "@chakra-ui/react";
import { XboxConsole } from "lib/coplay/types/BackendTypes";
import { XboxIcon } from "../XboxIcon";
import { XboxConsoleName } from "./XboxConsoleName";


function bytesToGB(bytes: number) {
    const bytesPerGB = 1024 * 1024 * 1024; // = 2^30
    return (bytes / bytesPerGB).toFixed(2);

}

export const ConsoleDetailsHeader = (props: { emailAddress: string, console: XboxConsole, onUnlink: () => void }) => {

    const iconColor = useColorModeValue('#000000', 'white');

    const regex = /(?<=[a-z])(?=[A-Z])/;
    const xboxType = props.console.consoleType.split(regex).join(' ')

    const storageDevices = () => {
        if (props.console.storageDevices) {
            return props.console.storageDevices.map((dev) => {
                return (
                    <div style={{ paddingLeft: '15px' }}>
                        <Text fontWeight={'bold'}>{dev.storageDeviceName}:</Text>
                        <Text>{bytesToGB(dev.freeSpaceBytes)} Gb Free ({bytesToGB(dev.totalSpaceBytes)} Gb Total) </Text>
                    </div>
                )
            })
        }
    }

    return (
        <div style={{ borderBottom: '1px' }}>
            <Flex alignContent={"center"} alignItems={"center"} pb="10px" >
                <Box px={"15px"}>
                    < XboxIcon color={iconColor} />
                    <Text><strong>Status:</strong> {props.console.powerState}</Text>
                </Box>
                <Box>
                    <XboxConsoleName console={props.console} emailAddress={props.emailAddress} />
                    <Text pb='5px'><strong>Type:</strong> {xboxType}</Text>
                    <Text><strong>ID:</strong> {props.console.id}</Text>
                </Box>
            </Flex  >
            <Flex justify={'space-between'}>
                {storageDevices()}
                <Button onClick={props.onUnlink} size="xs" py={'15px'} colorScheme='red' variant='solid'>Unlink <br />Console</Button>
            </Flex>
            <Spacer />
        </div>
    )
}
