import {
    useDisclosure, Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody
} from "@chakra-ui/react"
import ProfileModal from "lib/coplay/components/modals/ProfileModal"
import { PartialXMProfile } from "lib/coplay/types/BackendTypes"

export default function ProfileButton(props: { profile: PartialXMProfile }) {
    const disclosure = useDisclosure()

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        disclosure.onOpen()
    }

    return (
        <Text
            px="15px"
            color='green.500'
            fontWeight='bold'
            fontSize='xs'
            _hover={{ fontSize: "sm" }}
            onClick={onClick}
        >
            {props.profile.name}
            <Modal
                isOpen={disclosure.isOpen}
                size={"6xl"}
                onClose={disclosure.onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton style={{ zIndex: 1 }} />
                    <ModalBody>
                        <ProfileModal profileId={props.profile.id} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Text>
    )
}