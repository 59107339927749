import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button, Spinner, Text, Flex, Box, Icon, Center } from "@chakra-ui/react";
import { mintApiKey } from "lib/coplay/backend/ApiRequests";
import { MintKeyResponse, ResponseStatus } from "lib/coplay/types/BackendTypes";
import { useState } from "react";
import { useClipboard } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

export default function CreateKeyModal(props: {
    isOpen: boolean,
    onClose: () => void,
}) {
    const [viewState, setViewState]
        = useState<'loading' | 'error' | 'success' | 'form'>('form');
    const [response, setResponse] = useState<MintKeyResponse | null>(null);
    const [newKeyName, setNewKeyName] = useState('');

    const resetModal = () => {
        setViewState('form');
        setResponse(null);
        setNewKeyName('');
    };

    const handleClose = () => {
        resetModal();
        props.onClose();
    };

    const generateKey = async () => {
        try {
            setViewState('loading');
            const response = await mintApiKey(newKeyName);
            setResponse(response);
        } catch (error) {
            console.error("Failed to generate key:", error);
            setViewState('error');
        } finally {
            setViewState('success');

        }
    }

    return (
        <Modal isOpen={props.isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create new secret key</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {viewState === 'loading' && <LoadingScreen />}
                    {viewState === 'error' && <ErrorScreen />}
                    {viewState === 'success' && <KeyResponse response={response!} />}
                    {viewState === 'form' && (
                        <FormControl mb={4}>
                            <FormLabel>Name (Optional)</FormLabel>
                            <Input
                                value={newKeyName}
                                onChange={(e) => setNewKeyName(e.target.value)}
                                placeholder="My Test key" />
                        </FormControl>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={handleClose}>
                        {viewState === 'success' ? 'Close' : 'Cancel'}
                    </Button>
                    {viewState === 'form' && (
                        <Button colorScheme="teal" onClick={generateKey}>
                            Create secret key
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

const KeyResponse = (props: {
    response: MintKeyResponse
}) => {
    const { onCopy, hasCopied } = useClipboard(props.response.key);

    if (props.response.status === ResponseStatus.Success) {
        return (
            <>
                <FormControl mb={4}>
                    <FormLabel>Your new secret key:</FormLabel>

                    <Text
                        align="center"
                        outline="1px solid gray"
                        fontFamily="mono"
                        bg="gray.100"
                        fontSize="14px"
                        p={2}
                        borderRadius="md"
                    >{props.response.key}</Text>

                    <Center>
                        <Button
                            m={3}
                            size="sm"
                            colorScheme="teal"
                            onClick={onCopy}
                            leftIcon={<Icon as={CopyIcon} />}
                        >
                            {hasCopied ? "Copied!" : "Copy"}
                        </Button>
                    </Center>

                </FormControl>
                <Text mb={4} color="red.500" fontWeight="bold">
                    Important: Copy and store this key in a safe place. It will not be shown again after you close this window.
                </Text>
            </>
        )
    } else {
        return (
            <>
                <FormControl mb={4}>
                    <Text>Hmm... Something went wrong.</Text>
                    <FormLabel>Error</FormLabel>
                    <Text>{props.response.error.toString()}</Text>
                </FormControl>

            </>
        )
    }
}

const ErrorScreen = () => {
    return (
        <Text>Hmmm... Something went wrong. Try again later</Text>
    )
}

const LoadingScreen = () => {
    return (
        <Spinner />
    )
}