import { Box, Flex, Spacer, Text, useColorModeValue, Wrap, WrapItem } from "@chakra-ui/react";
import Card from "lib/horizon/components/card/Card";
import { memo } from "react";
import AddConsoleButton from "lib/coplay/components/modals/console/add_console/AddConsoleButton";
import ProfileLine from "lib/coplay/components/views/dashboard/dashboard_list/ProfileLine";
import { ConsoleDetailsButton } from "lib/coplay/components/modals/console/console_details/ConsoleDetailsButton";
import { UserAvatar } from "../UserAvatar";
import { UserStatusRenderer } from "./UserStatusRenderer";
import { XboxUser } from "lib/coplay/types/BackendTypes";


export function UserCard(props: { user: XboxUser, isSelected: boolean, onClick: Function, onSelect: Function, onDeselect: Function, onConsole: (user: XboxUser) => void }) {
    const secondaryColor = useColorModeValue('gray.400', 'white');
    const cardShadow = useColorModeValue('0px 0px 20px rgba(112, 144, 176, 0.12)', 'unset');
    const onHoverColor = useColorModeValue('brand.500', 'white');
    const bgHover = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');

    // Use to check rendering efficiency
    // console.log(`Rendering UserCard for user: ${ props.user.emailAddress }.Checked: ${ props.isSelected } `)
    // console.log("user: " + JSON.stringify(props.user))
    const consoleButton = props.user.consoleId ?
        <ConsoleDetailsButton consoleId={props.user.consoleId} consoleName={props.user.consoleName} consoleOs={props.user.consoleOS} emailAddress={props.user.emailAddress} onClick={() => props.onConsole(props.user)} /> :
        <AddConsoleButton enabled={false} onClick={() => props.onConsole(props.user)} />

    return (
        <Card
            background={props.isSelected ? bgHover : 'transparent'}
            _hover={{ color: onHoverColor, cursor: 'pointer', background: bgHover }}
            width="100%"
            boxShadow={cardShadow}
            mb='10px'
            p={{ base: '10px', md: '15px', lg: '15px', sm: '25px' }}>
            <Wrap align="center" spacingY="20px">
                <WrapItem>
                    <Flex alignItems="center" style={{ flexWrap: "wrap" }}>
                        {/* CHECKBOX */}
                        <Box px="15px" fontWeight='bold' fontSize='md'>
                            <label>
                                <input id={`${props.user.xuid}-checkbox`} style={{ height: '15px', width: '15px' }} type="checkbox" checked={props.isSelected}
                                    onClick={(e) => {
                                        if (props.isSelected) {
                                            console.log("Removing user from selected users")
                                            props.onDeselect(props.user)
                                        } else {
                                            console.log("Adding user to selected users")
                                            props.onSelect(props.user)
                                        }
                                    }}
                                    onChange={() => { }} />
                            </label>
                        </Box>
                        {/* Avatar Img, Email, Gamertag and Last Updated */}
                        <UserAvatar user={props.user} src={props.user.profilePicUrl} />
                        <Flex
                            style={{ width: "247px" }}
                            alignItems="center"
                            onClick={() => { props.onClick(props.user) }}
                        >
                            <Box>
                                <Text
                                    px="15px"
                                    fontWeight='bold'
                                    fontSize='md'
                                    style={{ overflowWrap: "anywhere" }}
                                >
                                    {props.user.emailAddress}
                                </Text>
                                <Text px="15px" fontWeight='bold' fontSize='xs'>
                                    {props.user.gamerTag}
                                </Text>
                                <ProfileLine profile={props.user.xmProfile} />
                                <Text color={secondaryColor} px="15px" fontWeight='bold' fontSize='xs'>
                                    Last Updated: {props.user.updatedAt.toLocaleString()}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </WrapItem>

                <WrapItem>
                    {consoleButton}
                </WrapItem>

                <Spacer onClick={() => { props.onClick(props.user) }} />
                <WrapItem onClick={() => { props.onClick(props.user) }}>
                    <Box width="150px" >
                        <Text px="15px" fontWeight='bold' fontSize='sm'>
                            Friends: {props.user.numFriends}
                        </Text>
                        <Text px="15px" fontWeight='bold' fontSize='sm'>
                            Conversations: {props.user.numConversations}
                        </Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <UserStatusRenderer user={props.user} onClick={() => { props.onClick(props.user) }} />
                </WrapItem>
                <WrapItem overflow={'hidden'} textOverflow={'ellipsis'} h="50px" w="180px" onClick={() => { props.onClick(props.user) }}>
                    <Text textAlign="center" color={secondaryColor} minW="150px" fontWeight='bold' fontSize='sm'>
                        {props.user.notes ? props.user.notes : "Notes"}
                    </Text>
                </WrapItem>
            </Wrap>
        </Card >
    )
}

export const MemoUserCard = memo(UserCard, (prevProps, nextProps) => {

    if (prevProps.user.emailAddress !== nextProps.user.emailAddress) {
        return false;
    }
    if (prevProps.user.gamerTag !== nextProps.user.gamerTag) {
        return false;
    }
    if (prevProps.user.numFriends !== nextProps.user.numFriends) {
        return false;
    }
    if (prevProps.user.numConversations !== nextProps.user.numConversations) {
        return false;
    }
    if (prevProps.user.notes !== nextProps.user.notes) {
        return false;
    }
    if (prevProps.user.presenceText !== nextProps.user.presenceText) {
        return false;
    }
    if (prevProps.user.profilePicUrl !== nextProps.user.profilePicUrl) {
        return false;
    }
    if (prevProps.user.updatedAt.toLocaleString() !== nextProps.user.updatedAt.toLocaleString()) {
        return false;
    }
    if (prevProps.user.userStatus.statusMessage !== nextProps.user.userStatus.statusMessage) {
        return false;
    }
    if (prevProps.user.userStatus.nextRequest !== nextProps.user.userStatus.nextRequest) {
        return false;
    }
    if (prevProps.isSelected !== nextProps.isSelected) {
        return false;
    }
    if (prevProps.user?.consoleId !== nextProps.user?.consoleId) {
        return false;
    }
    if (prevProps.user?.consoleName !== nextProps.user?.consoleName) {
        return false;
    }
    if (prevProps.user?.xmProfile?.id !== nextProps.user?.xmProfile?.id) {
        return false;
    }
    if (prevProps.user?.schedules !== nextProps.user?.schedules) {
        return false;
    }

    return true;
})