import { Button, Link, useColorModeValue } from "@chakra-ui/react"
import { passwordReset } from "lib/coplay/backend/FirebaseFunctions";
import { useHistory } from "react-router-dom";

export default function PasswordReset() {
    const textColorBrand = useColorModeValue("brand.500", "white");
    const history = useHistory();

    const onReset = () => {
        history.push("/sign-in/forgot-password");
    }

    return (
        <div style={{margin: '15px 20px 0px 5px'}}>
            <Link 
            fontWeight={'bold'}
            _hover={{ cursor: 'pointer' }} 
            color={textColorBrand} 
            onClick={onReset} isExternal>
            Reset Password
        </Link>
        </div>
    )
}