
// chakra imports
import {
    Box, Flex, HStack, useColorModeValue, useDisclosure, Text, Icon, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay
} from '@chakra-ui/react';
import { renderThumb, renderTrack } from 'lib/horizon/components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IconType } from 'react-icons';
import { IoMenuOutline } from 'react-icons/io5';



export function SidebarLink(props: { icon: IconType, label: string, routeName: string, children?: React.ReactNode[] }) {
    let location = useLocation();

    const activeRoute = (routeName: string) => {
        return location.pathname.includes(routeName);
    };

    let activeColor = useColorModeValue('gray.700', 'white');
    let activeIcon = useColorModeValue('brand.500', 'white');
    let textColor = useColorModeValue('secondaryGray.500', 'white');
    let brandColor = useColorModeValue('brand.500', 'brand.400');


    return (
        <NavLink key={props.routeName} to={props.routeName}>
            <div style={{ paddingLeft: "15px" }}>
                <HStack
                    spacing={'22px'}
                    py='5px'
                    ps='10px'>
                    <Flex w='100%' alignItems='center' justifyContent='center'>
                        <Box
                            color={activeRoute(props.routeName.toLowerCase()) ? activeIcon : textColor}
                            me='18px'>
                            <Icon as={props.icon} width='20px' height='20px' color='inherit' />
                        </Box>
                        <Text
                            me='auto'
                            color={activeColor}
                            fontWeight={activeRoute(props.routeName.toLowerCase()) ? 'bold' : 'normal'}>
                            {props.label}
                        </Text>
                    </Flex>
                    <Box
                        h='36px'
                        w='4px'
                        bg={activeRoute(props.routeName.toLowerCase()) ? brandColor : 'transparent'}
                        borderRadius='5px'
                    />
                </HStack>
                {props.children}
            </div>
        </NavLink>
    )
}






export function AppSidebar(props: { children: React.ReactNode }) {

    let variantChange = '0.2s linear';
    let shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
    let sidebarBg = useColorModeValue('white', 'navy.800');
    let sidebarMargins = '0px';

    return (
        <Box display={{ sm: 'none', xl: 'block' }} position='fixed' minH='100%' id="coplaySidebarContainer">
            <Box
                bg={sidebarBg}
                transition={variantChange}
                w='300px'
                h='100vh'
                m={sidebarMargins}
                minH='100%'
                overflowX='hidden'
                boxShadow={shadow}>

                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                >

                    {props.children}
                </Scrollbars>
            </Box>
        </Box>
    );
}


export function AppSidebarResponsive(props: { children: React.ReactNode[] }) {
    let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
    let menuColor = useColorModeValue('gray.400', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef();


    return (
        <Flex display={{ sm: 'flex', xl: 'none' }} alignItems='center'>
            <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
                <Icon
                    as={IoMenuOutline}
                    color={menuColor}
                    my='auto'
                    w='20px'
                    h='20px'
                    me='10px'
                    _hover={{ cursor: 'pointer' }}
                />
            </Flex>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement='left'
                finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent w='285px' maxW='285px' bg={sidebarBackgroundColor}>
                    <DrawerCloseButton
                        zIndex='3'
                        onClick={onClose}
                        _focus={{ boxShadow: 'none' }}
                        _hover={{ boxShadow: 'none' }}
                    />
                    <DrawerBody style={{ padding: 0 }} maxW='285px'>
                        <Scrollbars
                            autoHide
                            renderTrackVertical={renderTrack}
                            renderThumbVertical={renderThumb}
                        >
                            {props.children}
                        </Scrollbars>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}