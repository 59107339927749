export enum OauthActions {
    Aggregate = "aggregate",
    ReAggregate = "ReAggregate",
    Refresh = "refresh",
    AddFriends = "addFriends",
    RemoveFriends = "removeFriends",
    SyncFriends = "syncFriends",
    Delete = "delete",
    ClearConversations = "clearConversations",
    ChangeSettings = "changeSettings",

    QueryConsoles = "queryConsoles",
    LinkConsole = "linkConsole",
    UnlinkConsole = "unlinkConsole",
    InstallSoftware = "installSoftware",
    UninstallSoftware = "uninstallSoftware",
    BatchInstall = "batchInstall", // TODO maybe replace install software with this
    RenameConsole = "renameConsole",

    XMProfile = "XMProfile",

    AddSchedule = "addSchedule",
    RemoveSchedule = "removeSchedule",
    DoSchedule = "doSchedule", // TODO: Maybe remove?

    MintKey = "mintKey",
    DeleteKey = "deleteKey",
    ResetToProfile = "resetToProfile",
}




//
//
//  XBOX-USER
//
//



export type XboxUser = {
    emailAddress: string;
    gamerTag: string;
    xuid: string;
    profilePicUrl: string;
    numFriends: number;
    numConversations: number;
    updatedAt: Date; // TODO: Reconcile this with backend
    requestID: number; // For correlating data among different calls
    userStatus: UserStatus;
    presenceState: string;
    presenceText: string;
    consoleId?: string | null; // IF the user has a console linked
    consoleName?: string | null; // IF the user has a console linked
    consoleOS?: string | null; // IF the user has a console linked
    xmProfile?: PartialXMProfile | null,
    // lastSeenUTC: string;
    // subscriptions: string;
    notes?: string;
    schedules?: string[];
    hasScheduleWarnings?: boolean
}

export type UserStatus = {
    statusMessage: UserStatusMessage;
    nextRequest?: number;
    lastAction: {
        oauthAction: OauthActions;
        requestID: number;
    }
}

export enum UserStatusMessage {
    Error = "Error",
    Ready = "Ready",
    Warning = "Warning",
    Pending = "Almost There!",
    Refreshing = "Refreshing...",
    AddingFriends = "Adding Friends...",
    RemovingFriends = "Removing Friends...",
    SyncingFriends = "Syncing Friends...",
    ClearingMessages = "Clearing Messages...",
    Deleting = "Deleting User...",
    Working = "Working...",
    InProgress = "In Progress...",
    Unknown = "Unknown", // Should pretty much never happen
}

export type Friend = {
    xuid: string;
    gamertag: string;
}

export type Friends = {
    totalCount: number;
    people: Friend[];
}

export type PartialXMProfile = {
    id: string;
    name: string;
    status?: string;
}

export type XMProfile = {
    name: string;
    id: string;
    dateCreated: string; // iso string
    accountSettings?: {
        friending?: FriendingAction;
        privacySettings?: UserPrivacySettings;
        clearConversations?: boolean;
    },
    consoleSettings?: {
        games?: SoftwareSettings;
        apps?: SoftwareSettings;
    }
}

export enum FriendingAction {
    AddAll = "add",
    RemoveAll = "remove",
    SyncAll = "sync",
}

export type SoftwareSettings = {
    removeOthers: boolean;
    reinstall: boolean;
    software: SearchedSoftware[];  // Array of game IDs
}

export type UserPrivacySettings = {
    settings: [
        { setting: "ShareFriendList"; value: string; },
        { setting: "ShareGameHistory"; value: string; },
        { setting: "CommunicateUsingTextAndVoice"; value: string; },
        { setting: "SharePresence"; value: string; },
        { setting: "ShareProfile"; value: string; },
        { setting: "ShareVideoAndMusicStatus"; value: string; },
        { setting: "CommunicateUsingVideo"; value: string; },
        { setting: "ShareIdentity"; value: string; },
        { setting: "ShareRecordedGameSessions"; value: string; },
        { setting: "ShareIdentityTransitively"; value: string; },
        { setting: "AllowUserCreatedContentViewing"; value: string; },
        { setting: "AllowProfileViewing"; value: string; },
        { setting: "ShareClubMembership"; value: string; },
        { setting: "ShareActivityFeed"; value: string; },
        { setting: "CommunicateDuringCrossNetworkPlay"; value: string; },
        // { setting: "CollectVoiceData"; value: string; },
        // { setting: "ShareXboxMusicActivity"; value: string; },
        // { setting: "CollectXboxVideoData"; value: string; },
        // { setting: "ShareVideoHistory"; value: string; },
        // { setting: "ShareMusicHistory"; value: string; },
        // { setting: "ShareContentToExternalNetworks"; value: string; },
        // { setting: "CollectVoiceSearchData"; value: string; },
        // { setting: "CollectVoiceGameChatData"; value: string; },
    ];
};

export type XboxAuthorization = {
    gamerTag: string;
    xuid: string | undefined;
    userHash: string;
    authToken: string;
    refreshToken: string | undefined;
    issueInstant: string;
    expires: string;
    raw: any;
    authCode: string;
    authDate: Date;
    requestId: number;
}

export enum PrivilegeSetting {
    XPRIVILEGE_CROSS_PLAY = "You can play with people outside of Xbox Live",
    XPRIVILEGE_CLUBS = "You can create and join clubs",
    XPRIVILEGE_BROADCAST_GAMEPLAY = "Broadcast gameplay",
    XPRIVILEGE_GAME_DVR = "You can upload captures to Xbox Live",
    XPRIVILEGE_SHARE_KINECT_CONTENT = "You can share content made using Kinect or another camera",
    XPRIVILEGE_SOCIAL_NETWORK_SHARING = "Others can share your content to social networks",
    XPRIVILEGE_MULTIPLAYER_SESSIONS = "You can join multiplayer games",
    XPRIVILEGE_ADD_FRIEND = "You can add friends"
}

export type PrivilegeValue = "Allow" | "Block";

export type Privilege = {
    description: string;
    value: PrivilegeValue;
};



//
//
//  XBOX-CONVERSATIONS
//
//



export type GroupXblConversation = XBLConversation & {
    groupId: string;
}

// Messages
export type XBLConversation = {
    conversationId: string;
    type: string;
    lastMessage: {
        messageId: string;
        contentPayload: {
            content: any;
        }
    }
}

export type LastTextMessage = {
    lastMessage: {
        contentPayload: {
            content: {
                parts: {
                    contentType: "text";
                    text: string;
                }[]
            }
        }
    }
}
export type XBLConversations = {
    totalCount: number;
    unreadCount: number;
    conversations: XBLConversation[] | GroupXblConversation[];
    raw: any[]
}

export type XBLCollatedConversations = {
    primary: XBLConversations;
    secondary: XBLConversations;
    grouped: XBLConversations;
    totalConversations: number;
    totalUnread: number;
}



//
//
//  XBOX-CONSOLE
//
//



export type XboxConsole = {
    status?: {
        errorCode: string
        errorMessage: string | null
    }
    id: string
    name: string
    consoleType: string
    powerState: string
    remoteManagementEnabled: boolean
    consoleStreamingEnabled: boolean
    installedApps: InstalledSoftware[]
    installedGames: InstalledSoftware[]
    osVersion?: string
    userXboxCount?: string
    logs?: XboxConsoleLogs
    aggregatedAt?: number
    storageDevices?: XboxStorage[]
}

export type XboxStorage = {
    storageDeviceName: string
    isDefault: boolean
    freeSpaceBytes: number
    totalSpaceBytes: number
}


export type InstallEvent = {
    eventType: string; // "InstallCompleted" | "UninstallCompleted" | "InstallError" | "InstallProgress"
    eventTime: string; // "2023-12-04T12:25:47.566Z"
    hResult: number;
    oneStoreProductId: string; // BP5G8K2M71PM
    instanceId: string; // "{A89ECE52-7E8E-444F-BBD0-C68B76C2ECA4}#{BFEA5371-0925-487F-9712-7EC4B45E8EDD}"
    titleId: number;
    productName: string;
    totalBytes: number;
    streamedBytes: number;
    readyToPlayBytes: number;
    streamedReadyToPlayBytes: number;
    transferState: string; //"Complete" | "Running" | "Failed"
}

export type XboxConsoleLogs = {
    status: {
        errorCode: string
        errorMessage: string
    }
    installEvents: InstallEvent[]
}

export type SearchedSoftware = {
    name: string;
    productId: string;
    uniqueId: string;
    imageUri: string;
    compatibileWith?: string[];
}

export enum InstalledSoftwareType {
    App = "App",
    Game = "Game",
    Dlc = "Dlc",
    Unknown = "Unknown"
}

export type InstalledSoftware = {
    name: string
    isInstalling?: boolean
    contentType: InstalledSoftwareType
    instanceId: string
    oneStoreProductId: string
    titleId: number
    sizeInBytes: number
    lastActiveTime: number | null
    installTime: number | null,
    updateTime: number | null
}

export type SoftwareToAdd = {
    name: string
    productId: string
}

export type SoftwareToRemove = {
    name: string
    instanceId: string
}



//
//
//  Schedules
//
//



export enum ScheduleFrequency {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
    OneTime = "oneTime",
    Minutely = "minutely" // TESTING ONLY
}

export type ScheduleEvent = {
    name?: string,
    scheduleId: string,
    scheduledRequest: FriendingRequest | BatchRequest,
    frequency: ScheduleFrequency;
    time: string;
    startDate: number,
    endDate: number,
}



//
//
//  REQUESTS
//
//



export type BasicRequest = {
    firebaseToken: string;
    requestId: number;
    oauthAction: OauthActions;
    previous?: number[],
}

export type BatchRequest = BasicRequest & {
    emailAddresses: string[];
};

export type AggRequest = BasicRequest & {
    oauthAction: OauthActions.Aggregate | OauthActions.ReAggregate
    xAuthCode: string;
};

export type FriendingRequest = BasicRequest & BatchRequest & {
    friendXuids: string[];
};

export type QueryConsoleRequest = BasicRequest & {
    emailAddress: string;
};

export type RenameConsoleRequest = BasicRequest & {
    emailAddress: string;
    name: string
    consoleId: string
}

export type LinkConsoleRequest = BasicRequest & {
    emailAddress: string;
    consoleId: string;
};

export type InstallRequest = BasicRequest & {
    emailAddress: string;
    consoleId: string;
    productIds: string[];
};

export type BatchInstallRequest = BatchRequest & {
    software: SearchedSoftware[];
};

export type UninstallRequest = BasicRequest & {
    emailAddress: string;
    consoleId: string;
    instanceIds: string[];
};

export type SettingsRequest = BasicRequest & BatchRequest & {
    settings: UserPrivacySettings;
}

export type XMProfileRequest = BasicRequest & BatchRequest & {
    action: 'unset' | 'sync' | 'set';
    profile?: PartialXMProfile;
}

// Can be a retry or a schedule
export type FutureRequest = {
    isRetry: boolean;
    scheduleId: string;
    xmToken: string;
    req?: FriendingRequest | BatchRequest | XMProfileRequest;
}

export type ScheduleRequest = BatchRequest & { scheduledEvent: ScheduleEvent };

export type RemoveScheduleRequest = BasicRequest & {
    scheduleId: string;
    emailAddress: string;
};

export type MintKeyRequest = BasicRequest & {
    oauthAction: OauthActions.MintKey;
    name: string
}

export type DeleteKeyRequest = BasicRequest & {
    oauthAction: OauthActions.DeleteKey;
    keyId: string;
}

export type ResetToProfileRequest = BasicRequest & {
    oauthAction: OauthActions.ResetToProfile;
    key: string;
    emailAddress: string;
}


//
//
//  RESPONSES
//
//


// TODO: Change these to be string enums, not http codes. I'll have to make this change
// on the front end as well though. 
export enum ResponseStatus {
    Success = 200,  // 'Ok'
    Fail = 500,     // 'server error'
    Mixed = 207,    // 'multi status'     (some success, some fail)
    Warning = 206,  //  Partial Content'  (Just about fully succeeded)
    Pending = 202,   // 'accepted'
    LockFail = 423 // 'locked'
}

export type BasicResponse = {
    emailAddress: string;
    oauthAction: OauthActions;
    status: ResponseStatus;
    requestId: number;
    error?: XMError;
}

export type AggResponse = BasicResponse & {
    user?: XboxUser;
}

export type RefreshResponse = BasicResponse & {
    user?: XboxUser;
}

export type DeleteResponse = BasicResponse & {
    userDeleted: string;
}

export type XMProfileResponse = BasicResponse & {
    profileId: string;
    profileAction: 'unset' | 'sync' | 'set';
    profileError?: ProfileError;
}

export type ConversationResponse = BasicResponse & {
    messagesDeleted: string[],
    numConversationsCleared: number;
}

export type QueryConsoleResponse = BasicResponse & {
    consoleIds: string[];
}

export type LinkConsoleResponse = BasicResponse & {
    consoleId: string;
}

export type FriendingDetails = {
    friendsAdded: Friends;
    friendsRemoved: Friends;
}

export type FriendingResponse = BasicResponse & FriendingDetails;

export type MintKeyResponse = {
    oauthAction: OauthActions.MintKey;
    name: string;
    keyId: string;
    key: string;
    status: ResponseStatus;
    requestId: number;
    error?: XMError;
}

export type DeleteKeyResponse = {
    oauthAction: OauthActions.DeleteKey;
    status: ResponseStatus;
    keyId: string;
    error?: XMError;
    requestId: number;
}

export type ResetToProfileResponse = {
    oauthAction: OauthActions.ResetToProfile;
    status: ResponseStatus;
    error?: XMError;
}


//
//
//  REPORTS
//
//



export type BasicReport = {
    statusCode: ResponseStatus;
    oauthAction: OauthActions;
    Date: Date; //  Date | Timestamp; 
    numUsers: number;
    numSuccess: number; // Typo fized
    numFails: number;
    requestId: number;
    errors?: BasicResponse[];
}

export type AggReport = Omit<BasicReport, 'oauthAction'> & {
    oauthAction: OauthActions.Aggregate;
}

export type DeleteReport = Omit<BasicReport, 'oauthAction'> & {
    oauthAction: OauthActions.Delete;
}

export type RefreshReport = Omit<BasicReport, 'oauthAction'> & {
    oauthAction: OauthActions.Refresh;
}

export type FriendingReport = Omit<BasicReport, 'oauthAction' | 'errors'> & {
    oauthAction: OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends;
    totalFriendsRemoved: number,
    totalFriendsAdded: number,
    errors?: FriendingResponse[];
}

export type ConversationReport = BasicReport & {
    responses: ConversationResponse[];
    totalConversationsCleared: number
}

export type ProfileReport = Omit<BasicReport, 'oauthAction' | 'errors'> & {
    oauthAction: OauthActions.XMProfile;
    errors?: XMProfileResponse[];
}



//
//
//  DATABASE
//
//



export type XMUser = {
    email: string;
    uid: string;
    raw?: any;
}



//
//
//  Other
//
//

export type LogEntry = {
    msg: string,
    response?: XMResponse,
    timestamp?: string
}

export type XMResponse = BasicResponse | AggResponse | RefreshResponse | ConversationResponse | FriendingResponse; // QueryConsoleResponse | LinkConsoleResponse | DeleteResponse

export type XMError = {
    userMessage: string;
    errorMessage: string;
    suggestion: string;
}

export type ProfileError = {
    conversations?: ConversationResponse;
    settings?: BasicResponse;
    friending?: BasicResponse;
    apps?: BasicResponse;
    games?: BasicResponse;
}

export type XuidsToSync = {
    friendsToAdd: string[];
    friendsToRemove: string[];
}

export enum UsageMetrics {
    UsersAdded = "usersAdded",
    UsersRemoved = "usersRemoved",
    FriendsAdded = "friendsAdded",
    FriendsRemoved = "friendsRemoved",
    ConversationsCleared = "conversationsCleared",
    SettingsApplied = "settingsChanged",
    SoftwareInstalled = "softwareInstalled",
    SoftwareRemoved = "softwareRemoved",
    // StreamingAppsReset = "streamingAppsReset"
}

export type UsageReport = {
    actions: Partial<{ [key in OauthActions]: [number, number] }>, // oauthAction: [numSuccessful, numErrors]
    metrics: { [key in UsageMetrics]: number } // metric: number
}

export type MonthlyReport = {
    totals: UsageReport
    days: UsageReport[]
    // keep track of the last 10 requests 
    requestLog: (BasicReport | BasicResponse)[]
}

export type XMAPIKeyCrumb = {
    keyId: string
    name: string,
    crumb: string,
    createdAt: string
}

export type XMAPIKey = XMAPIKeyCrumb & {
    hash: string // hashed and encrypted	
    xmToken: string
}
