import React from 'react';
import ReactDOM from 'react-dom';
import 'lib/horizon/assets/css/App.css';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'lib/horizon/theme/theme';
import { DataProvider } from 'lib/coplay/components/DataProvider';
import CoPlayRouter from 'lib/coplay/components/CoplayRouter';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<DataProvider>
				<CoPlayRouter />
			</DataProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);